import { marked } from 'marked'
import styled from '@emotion/styled'
import { theme } from '@dfds-ui/theme'

const extension = {
  useNewRenderer: true,
  renderer: {
    link({ href, title, tokens }) {
      const isLocalLink =
        href.startsWith('https://www.dfds.com') ||
        href.startsWith('https://dfds.com')

      const text = this.parser.parseInline(tokens)

      return `<a href="${href}" title="${title}" ${isLocalLink ? '' : 'target="_blank" rel="noopener nofollow"'}>${text}</a>`
    },
  },
}

marked.use(extension)

const Container = styled.div`
  p,
  div {
    max-width: 100%;
    ${(props) => props.noBottomMargin && 'margin-bottom: 0'};
  }

  table {
    max-width: 100%;
    width: 100%;
    margin: 16px 0;

    thead {
      tr {
        th {
          padding: 10px 14px;
          text-align: left;
          vertical-align: bottom;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: ${theme.colors.secondary.light}40;
        }

        td {
          padding: 10px 14px;
          font-size: 14px;
          line-height: 20px;
          border-right: 1px solid ${theme.colors.surface.primary};

          &:first-of-type {
            font-weight: 700;
          }
        }
      }
    }
  }

  ul {
    list-style-type: disc;
  }
`

const Markdown = (props) => {
  const { source, noBottomMargin } = props

  return source ? (
    <Container
      dangerouslySetInnerHTML={{
        __html: marked(source, {
          silent: true,
        }),
      }}
      noBottomMargin={noBottomMargin}
    />
  ) : null
}

export default Markdown
